import React, { useState, useEffect } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Button,
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Select,
  useDisclosure,
  useToast,
  Textarea,
  Divider,
} from "@chakra-ui/react";

// import global component
import Loading from "../../global/Loading";

// import types
import {
  driverBoardTypes,
  sourcesTypes,
  costCentersTypes,
  recruitersTypes,
  adUsersTypes,
  trainerInformation,
  ChangeLogEntry,
} from "../../../types/Types";

// import hooks
import { apiRead, apiWrite } from "../../../api/driverBoardAPI";

// import child components
import DriverInfo from "./DriverInfo";
import ProcessorForm from "./ProcessorForm";
import OrientationInfoForm from "./OrientationInfoForm";
import DriverDocuments from "./DriverDocuments";
import CompanyInfo from "./CompanyInfo";
import BasicInfo from "./BasicInfo";
import DriverBoardNotes from "./DriverBoardNotes";
import DriverBoardApprovalNotes from "./DriverBoardApprovalNotes";

const ApproveList = [
  "kia.gardner@hirschbach.com",
  "austin.stubbs@hirschbach.com",
  "tasha.anderson@hirschbach.com",
  "pader.vue@hirschbach.com",
  "taylor.schreiber@hirschbach.com",
  "phi.tran@hirschbach.com",
  "jessie@hirschbach.com",  
  "dani.simmons@hirschbach.com",
  "kathi.simenson@hirschbach.com",
  "sidney.gile@hirschbach.com",
];

interface EditFormType {
  userName: string;
  userEmail: string;
  userDepartment: string;
  isOpen: boolean;
  onClose: () => void;
  onRefresh: () => void;
  selectedID: number | null;
  profileLoading: boolean;
  profileData: driverBoardTypes | null;
  refreshProfileData: () => void;
  setSelectedID: (value: number | null) => void;
  historyLoading: boolean;
  changeLog: ChangeLogEntry[];
}

const EditForm: React.FC<EditFormType> = ({
  userName,
  userEmail,
  userDepartment,
  isOpen,
  onClose,
  onRefresh,
  selectedID,
  profileLoading,
  profileData,
  refreshProfileData,
  setSelectedID,
  changeLog,
}) => {
  const isApprover = ApproveList.includes(userEmail.toLowerCase());

  const isRecruiter =
    (profileData?.RecruiterEmail ?? "").toLowerCase() ===
    userEmail.toLowerCase();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const toast = useToast();
  const {
    isOpen: isWithdrawOpen,
    onOpen: onWithdrawOpen,
    onClose: onWithdrawClose,
  } = useDisclosure();
  const {
    isOpen: isReinstateOpen,
    onOpen: onReinstateOpen,
    onClose: onReinstateClose,
  } = useDisclosure();

  const {
    isOpen: isUpgradeOpen,
    onOpen: onUpgradeOpen,
    onClose: onUpgradeClose,
  } = useDisclosure();

  const {
    isOpen: isVersioningOpen,
    onOpen: onVersioningOpen,
    onClose: onVersioningClose,
  } = useDisclosure();

  const [driverleaders, setDriverLeaders] = useState<adUsersTypes[] | null>(
    null
  );
  const [sources, setSources] = useState<sourcesTypes[] | null>(null);
  const [costCenters, setCostCenters] = useState<costCentersTypes[] | null>(
    null
  );
  const [recruiters, setRecruiters] = useState<recruitersTypes[] | null>(null);
  const [trainers, setTrainers] = useState<trainerInformation[] | null>(null);
  const [WithdrawalReason, setWithdrawalReason] = useState<string>("");
  const [WithdrawalComment, setWithdrawalComment] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [documentCount, setDocumentCount] = useState<number>(0);

  // Group changes by datetime
  const groupedChanges = changeLog.reduce(
    (acc: { [key: string]: ChangeLogEntry[] }, change: ChangeLogEntry) => {
      const datetime = new Date(change.changedOn).toLocaleString("default", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      if (!acc[datetime]) {
        acc[datetime] = [];
      }
      acc[datetime].push(change);
      return acc;
    },
    {}
  );

  // Get Sources for the form
  const fetchSources = async () => {
    try {
      const payload = {
        process: "source",
      };
      const response = await apiRead(payload);
      setSources(response);
    } catch (error) {}
  };

  // Get Cost Centers for the form
  const fetchCostCenters = async () => {
    try {
      const payload = {
        process: "cost center",
      };
      const response = await apiRead(payload);
      setCostCenters(response);
    } catch (error) {}
  };

  // Get Recruiters for the form
  const fetchRecruiters = async () => {
    try {
      const payload = {
        process: "recruiter",
      };
      const response = await apiRead(payload);
      setRecruiters(response);
    } catch (error) {}
  };

  // Get driver leaders for the form
  const fetchDriverLeaders = async () => {
    try {
      const payload = {
        process: "driver leaders",
      };
      const response = await apiRead(payload);
      setDriverLeaders(response);
    } catch (error) {}
  };

  const fetchTrainers = async () => {
    try {
      const payload = {
        process: "trainers",
      };
      const response = await apiRead(payload);
      setTrainers(response);
    } catch (error) {}
  };

  // Function to send API request to withdraw driver
  const withdrawDriver = async () => {
    setIsLoading(true);
    if (WithdrawalReason === "" || WithdrawalComment === "") {
      toast({
        title: "Error",
        description: "Please fill out the withdraw reason and add a comment",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    const payload = {
      Process: "withdraw",
      id: profileData?.ID,
      form: {
        WithdrawnBy: userEmail,
        WithdrawalReason,
        WithdrawalComment,
        Status: profileData?.Status,
      },
    };

    try {
      await apiWrite(payload);
      onRefresh();
      refreshProfileData();
      onWithdrawClose();
      toast({
        title: "Driver Withdrawn",
        description: "The driver has been successfully withdrawn",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      setIsLoading(false);
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error withdrawing the driver",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      setIsLoading(false);
    }
  };

  // Function to reinstate driver
  const reinstateDriver = async () => {
    setIsLoading(true);
    const payload = {
      userEmail,
      Process: "reinstate",
      id: profileData?.ID,
    };

    try {
      const response = await apiWrite(payload);
      setSelectedID(response);
      onRefresh();
      onReinstateClose();
      toast({
        title: "Driver Reinstated",
        description: "The driver has been successfully reinstated",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      setIsLoading(false);
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error reinstating the driver",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      setIsLoading(false);
    }
  };

  // Function to upgrade driver
  const upgradeTrainee = async () => {
    setIsLoading(true);
    const payload = {
      userEmail,
      Process: "trainee upgrade",
      id: profileData?.ID,
    };

    try {
      const response = await apiWrite(payload);
      setSelectedID(response);
      onRefresh();
      onReinstateClose();
      toast({
        title: "Driver Upgraded",
        description: "The driver has been successfully upgraded!",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      setIsLoading(false);
      onUpgradeClose();
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error reinstating the driver",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSources();
    fetchCostCenters();
    fetchRecruiters();
    fetchDriverLeaders();
    fetchTrainers();
  }, []);

  useEffect(() => {
    setTabIndex(0);
  }, [selectedID]);

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent borderRadius="xl" m="10px">
          <DrawerHeader borderBottomWidth="1px">
            <HStack justifyContent="space-between">
              <Text fontSize="2xl" fontWeight="normal" color="blackAlpha.800">
                {profileData?.FirstName || ""} {profileData?.LastName || ""}
              </Text>

              <HStack spacing="10px">
                {(profileData?.Status === "New" ||
                  profileData?.Status === "Approved" ||
                  profileData?.Status === "Scheduled") && (
                  <Button size="sm" onClick={onWithdrawOpen}>
                    Withdraw
                  </Button>
                )}

                {profileData?.Status === "Withdrawn" && (
                  <Button
                    size="sm"
                    onClick={onReinstateOpen}
                    isLoading={isLoading}
                  >
                    Reinstate
                  </Button>
                )}
                {profileData?.Status === "Seated" &&
                  profileData?.Registration !== "Experienced" && (
                    <Button
                      size="sm"
                      isLoading={isLoading}
                      onClick={onUpgradeOpen}
                    >
                      Upgrade
                    </Button>
                  )}
                <Button
                  size="sm"
                  isLoading={isLoading}
                  onClick={onVersioningOpen}
                >
                  Change Log
                </Button>
              </HStack>
            </HStack>
          </DrawerHeader>
          <DrawerBody>
            {profileLoading ? (
              <Loading />
            ) : (
              <Box h="100%" w="100%">
                <Tabs
                  position="relative"
                  variant="unstyled"
                  h="100%"
                  w="100%"
                  index={tabIndex}
                  onChange={(index) => setTabIndex(index)}
                >
                  <TabList fontWeight="semibold">
                    <Tab fontWeight="semibold">Information</Tab>
                    <Tab fontWeight="semibold">Notes</Tab>
                    {isApprover && <Tab fontWeight="semibold">Manager</Tab>}
                    <Tab fontWeight="semibold">Orientation</Tab>
                    <Tab fontWeight="semibold">Company</Tab>
                    <Tab fontWeight="semibold">Processing</Tab>
                    <Tab fontWeight="semibold">Basic</Tab>
                    <Tab fontWeight="semibold">Documents</Tab>
                  </TabList>
                  <TabIndicator
                    mt="-1.5px"
                    height="3px"
                    bg="#1D61AA"
                    borderRadius="1px"
                  />

                  <TabPanels>
                    <TabPanel>
                      <DriverInfo
                        profileData={profileData}
                        refreshProfileData={refreshProfileData}
                        userEmail={userEmail}
                        onRefresh={onRefresh}
                        documentCount={documentCount}
                        isApprover={isApprover}
                        isRecruiter={isRecruiter}
                      />
                    </TabPanel>
                    <TabPanel>
                      <DriverBoardNotes
                        userEmail={userEmail}
                        id={profileData?.ID || ""}
                        userName={userName}
                        userDepartment={userDepartment}
                      />
                    </TabPanel>
                    {isApprover && (
                      <TabPanel>
                        <DriverBoardApprovalNotes
                          userEmail={userEmail}
                          id={profileData?.ID || ""}
                          userName={userName}
                          userDepartment={userDepartment}
                          profileData={profileData}
                          isApprover={isApprover}
                          isRecruiter={isRecruiter}
                        />
                      </TabPanel>
                    )}
                    <TabPanel>
                      <OrientationInfoForm
                        profileData={profileData}
                        refreshProfileData={refreshProfileData}
                        userEmail={userEmail}
                        onRefresh={onRefresh}
                        trainers={trainers}
                      />
                    </TabPanel>
                    <TabPanel>
                      <CompanyInfo
                        profileData={profileData}
                        refreshProfileData={refreshProfileData}
                        userEmail={userEmail}
                        onRefresh={onRefresh}
                        sources={sources}
                        costCenters={costCenters}
                        recruiters={recruiters}
                        driverleaders={driverleaders}
                      />
                    </TabPanel>
                    <TabPanel>
                      <ProcessorForm
                        profileData={profileData}
                        refreshProfileData={refreshProfileData}
                        userEmail={userEmail}
                        onRefresh={onRefresh}
                      />
                    </TabPanel>
                    <TabPanel>
                      <BasicInfo
                        profileData={profileData}
                        refreshProfileData={refreshProfileData}
                        userEmail={userEmail}
                        onRefresh={onRefresh}
                      />
                    </TabPanel>

                    <TabPanel>
                      <DriverDocuments
                        userEmail={userEmail}
                        id={profileData?.ID || ""}
                        userName={userName}
                        userDepartment={userDepartment}
                        setDocumentCount={setDocumentCount}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {/* Withdrawal Modal */}
      <Modal
        isOpen={isWithdrawOpen}
        onClose={onWithdrawClose}
        size="md"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Withdrawal</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Reason for Withdrawal</FormLabel>
              <Select
                placeholder="Select reason"
                onChange={(e) => setWithdrawalReason(e.target.value)}
                value={WithdrawalReason}
              >
                <option value="Different Offer">Different Offer</option>
                <option value="Personal Reasons">Personal Reasons</option>
                <option value="Sick">Sick</option>
                <option value="DNQ - Background">DNQ - Background</option>
                <option value="DNQ - Criteria">DNQ - Criteria</option>
                <option value="DNQ - Not A Good Fit">
                  DNQ - Not A Good Fit
                </option>
                <option value="DNQ - VOE">DNQ - VOE</option>
                <option value="Ghosted">Ghosted</option>
                <option value="Hair Test">Hair Test</option>
                <option value="Pushed Back Start Date">
                  Pushed Back Start Date
                </option>
              </Select>
            </FormControl>
            {/* Textarea with fixed height allowing recruiter to add in note about the withdrawal */}
            <FormControl mt={4}>
              <FormLabel>Comment</FormLabel>
              <Textarea
                placeholder="Add a comment"
                value={WithdrawalComment}
                onChange={(e) => setWithdrawalComment(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onWithdrawClose}
              mr={3}
              width="100px"
              isDisabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              colorScheme="telegram"
              width="100px"
              onClick={withdrawDriver}
              isLoading={isLoading}
            >
              Withdraw
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Reinstate Modal */}
      <Modal
        isOpen={isReinstateOpen}
        onClose={onReinstateClose}
        size="md"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reinstate</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text>
              Are you sure you want to reinstate {profileData?.FirstName || ""}{" "}
              {profileData?.LastName || ""}?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onReinstateClose}
              mr={3}
              width="100px"
              isDisabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              colorScheme="telegram"
              width="100px"
              onClick={reinstateDriver}
              isLoading={isLoading}
            >
              Reinstate
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isUpgradeOpen}
        onClose={onUpgradeClose}
        size="md"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upgrade Trainee</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text>
              Are you sure you want to upgrade {profileData?.FirstName || ""}{" "}
              {profileData?.LastName || ""} to a driver?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onUpgradeClose}
              mr={3}
              width="100px"
              isDisabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              colorScheme="telegram"
              width="100px"
              onClick={upgradeTrainee}
              isLoading={isLoading}
            >
              Upgrade
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isVersioningOpen}
        onClose={onVersioningClose}
        size="6xl"
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Version History</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6} maxH="80vh" overflowY="auto">
            {Object.entries(groupedChanges).map(([date, changes]) => (
              <Box key={date} mb={2}>
                <Text fontSize="sm" fontWeight="semibold" mb="5px">
                  {date} - {changes[0].changedBy}
                </Text>
                {changes.map((change, index) => (
                  <Box key={index} mb={1}>
                    <Text fontSize="xs">
                      {change.field}: {change.newValue}
                    </Text>
                  </Box>
                ))}
                <Divider mt="5px" />
              </Box>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditForm;
